<!-- 联系我们 -->
<template>
    <div class="contact-us">
        <div class="banner">
            <div class="info">
                <h2>更多<span>机遇</span>，更多<span>精彩</span></h2>
                <p>
                    与优秀的人一起工作，谈笑间获得经过时间沉淀的启示，在一个充满潜力的创业团队，实现“坐上火箭”的梦想。
                </p>
            </div>
        </div>
        <!-- 面包屑 -->
        <!-- 面包屑 -->
        <div class="bread-crumb">
            <div class="crumb">
                <span>平正云科技</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb">
                <span>公司</span>
                <img src="../../assets/customerCase/right@3x.png" alt="" />
            </div>
            <div class="crumb">
                <span>联系我们</span>
                <!-- <img src="../../assets/customerCase/right@3x.png" alt="" /> -->
            </div>
        </div>
        <!--  -->
        <div class="content">
            <section id="contact">
                <h4>联系我们</h4>
                <div class="container">
                    <div class="list">
                        <div class="item" v-for="(item, index) in list" :key="index">
                            <img :src="item.icon" alt="" />
                            <p>{{ item.title }}</p>
                        </div>
                    </div>
                    <div class="map">
                        <div class="img"></div>
                        <!-- <img src="../../assets/contactUs/map-m.png" alt="" /> -->
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            list: [
                { icon: require("../../assets/contactUs/icon1.png"), title: "166 2138 4819" },
                { icon: require("../../assets/contactUs/icon2.png"), title: "周一至周五  09:00-18:00" },
                { icon: require("../../assets/contactUs/icon3.png"), title: "zhuanghainan@pingzhengyun.com" },
                {
                    icon: require("../../assets/contactUs/icon4.png"),
                    title: "上海市杨浦区纪念路8号财大科技园5号楼110室",
                },
            ], //
        };
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 751px) {
    @import "contactUs_PC.scss";
}
@media screen and (max-width: 750px) {
    @import "contactUs_mobile.scss";
}
</style>
